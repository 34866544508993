
import Vue from "vue";
import { Rq, Table } from "@/lib/amdt";
import Api from "@/lib/api";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import VForm from "@/lib/types/v-form";
import ABtn from "@/components/elements/a-btn.vue";
import { required } from "@/lib/validations";
import IPerson from "@/lib/interfaces/person";
import IMultipleMoviment from "@/lib/interfaces/multiple-moviment";
import { currency as currencyFormat } from "@/lib/a-format";
import ATextFieldCurrency from "@/components/elements/a-text-field-currency.vue";

export default Vue.extend({
  name: "ReportsRegion",

  components: { AAutocomplete, ABtn, ATextFieldCurrency },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",
      valid: false,
      snackbar: false, // Variável para controlar o snackbar
      snackbarMsg: "",
      snackbarColor: "red darken-1",
      regioes: new Array<IPerson>(),
      dataQuery: {
        regiao_id: "", // Mantemos regiao_id como string
      },
      movimentos: new Array<IMultipleMoviment>(),
      inputTotal: "", // Atualize para uma string vazia
      totalRestante: "",
      totalRestanteClass: "", // Variável para o valor restante
      requestSlice: [] as Array<any>, // Slice para a request
    };
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    isSmallerInputsFilled(): boolean {
      // Verifica se algum input menor está preenchido
      return this.movimentos.some((movimento) => movimento.inputValue !== "");
    },

    // Calcula o valor total inicial dos movimentos
    totalValor(): string {
      const total = this.movimentos.reduce((acc, movimento) => {
        const valor = parseFloat(movimento.valor ?? "") || 0;

        // Se o tipo for 1, subtrai o valor
        if (movimento.tipo === "1") {
          return acc - valor;
        }

        // Se o tipo for 2, soma o valor
        if (movimento.tipo === "2") {
          return acc + valor;
        }

        return acc;
      }, 0);

      return total.toFixed(2);
    },

    // Calcula o valor inserido nos campos de entrada
    totalInput(): number {
      return this.movimentos.reduce((acc, movimento) => {
        // Remove os caracteres de formatação como "R$", "." e substitui "," por "."
        const sanitizedValue = movimento.inputValue
          .replace(/[^\d,]/g, "") // Remove tudo que não for dígito ou vírgula
          .replace(",", "."); // Substitui a vírgula decimal por ponto

        const inputValue = parseFloat(sanitizedValue) || 0; // Converte para número

        return acc + inputValue;
      }, 0);
    },
  },

  watch: {
    inputTotal(value: string) {
      this.updateTotalRestante();
    },
    movimentos: {
      handler() {
        this.updateInputTotal();
      },
      deep: true,
    },
  },

  mounted() {
    this.getRegioes().then();
  },

  methods: {
    required,

    async getRegioes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.read", {
          tipo: "EMPRESA",
          desativado: "0",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.regioes = rsp.getTable("pessoas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    submitForm() {
      if (!this.dataQuery.regiao_id) {
        this.snackbarColor = "red darken-1";
        this.snackbar = true;
        this.snackbarMsg = "Selecione uma região para continuar!";
      } else {
        this.snackbar = false;
        this.loading = true;

        const rq = new Rq("contas.getContaLancamento", {
          regiao_id: this.dataQuery.regiao_id,
        });

        Api.request(rq)
          .then((rsp) => {
            console.log("Resposta da API:", rsp);
            if (rsp.ret_id < 1) {
              this.ret_id = rsp.ret_id;
              this.ret_msg = rsp.ret_msg;
              return;
            }

            const movimentos =
              rsp.getTable("centro_custo")?.getRowsObject() ?? [];
            console.log("Movimentos retornados:", movimentos);

            this.movimentos = movimentos.map(
              (movimento: IMultipleMoviment) => ({
                ...movimento,
                inputValue: "", // Inicializa o campo de input
              })
            );
          })
          .catch((error) => {
            console.error("Erro ao buscar os movimentos:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    currency(
      value: string | number,
      currency: "EUR" | "USD" | "BRL",
      showSymbol?: boolean
    ): string {
      return currencyFormat(value, currency, showSymbol);
    },

    getValorClass(movimento: IMultipleMoviment): string {
      return movimento.tipo == "1" ? "red--text" : "blue--text";
    },

    // Método para atualizar o total do input ao inserir valores
    updateInputTotal() {
      const total = this.totalInput;
      this.inputTotal = total > 0 ? total.toFixed(2) : "";
      this.updateTotalRestante();
    },

    // Método para capturar alterações no input total e atualizar o totalRestante
    updateTotalRestante() {
      // Obter o valor inserido e remover o prefixo "R$ "
      const totalInseridoString = this.inputTotal
        .replace("R$ ", "")
        .replace(/\./g, "")
        .replace(",", "."); // Remove os caracteres não numéricos e substitui vírgula por ponto
      const totalInserido = parseFloat(totalInseridoString) || 0; // Converte para número

      // Pegar o total calculado anteriormente
      const total = parseFloat(this.totalValor) || 0;

      // Atualizar o restante baseado no tipo da linha
      let restante;

      restante = total - totalInserido;

      // Atualiza o totalRestante corretamente
      this.totalRestante = restante.toFixed(2);

      // Aplica a classe vermelha se o totalRestante for menor que zero
      this.totalRestanteClass = restante < 0 ? "red--text" : "";
    },

    // Método para lidar com a mudança de um campo input e montar a slice de request já no formato correto
    handleInputChange(movimento: IMultipleMoviment) {
      // Obter o valor inserido e remover o prefixo "R$ "
      const inputValueString = movimento.inputValue
        .replace("R$ ", "")
        .replace(/\./g, "")
        .replace(",", "."); // Remove os caracteres não numéricos e substitui vírgula por ponto
      const inputValue = parseFloat(inputValueString) || 0; // Converte para número

      const index = this.requestSlice.findIndex(
        (item) => item.centro_custo_id === movimento.centro_custo_id
      );

      // Se o valor for maior que 0, adicione ou atualize o item na slice
      if (inputValue > 0) {
        const newItem = {
          ...movimento,
          valor: inputValue, // Usamos o valor correto sem formatação
        };

        if (index !== -1) {
          this.requestSlice[index] = newItem;
        } else {
          this.requestSlice.push(newItem);
        }
      } else {
        this.requestSlice.splice(index, 1);
      }

      // console.log("Request slice atualizada:", this.requestSlice);
    },

    // Método para remover o item da slice quando o campo de input for limpo
    removeFromRequestSlice(movimento: IMultipleMoviment) {
      const index = this.requestSlice.findIndex(
        (item) => item.centro_custo_id === movimento.centro_custo_id
      );

      if (index !== -1) {
        this.requestSlice.splice(index, 1); // Remove o item da slice
      }
    },
    baixar() {
      // Criação da requisição no padrão esperado
      const rq = new Rq("contas.lancamento");

      // Criação da tabela 'centro_custo'
      const tblCentroCusto = new Table("centro_custo");

      // Loop para adicionar as colunas e as linhas de cada movimento
      this.requestSlice.forEach((movimento) => {
        // Montando o objeto com as colunas que serão enviadas
        const obj = {
          centro_custo_id: movimento.centro_custo_id,
          centro_custo_descricao: movimento.centro_custo_descricao,
          tipo: movimento.tipo || "2", // Assumindo que "2" é o tipo por padrão
          valor: movimento.valor.toString(), // Convertendo o valor para string
        };

        // Adicionando as colunas a partir do objeto
        tblCentroCusto.setColsFromObject(obj);

        // Adicionando a linha correspondente ao movimento
        tblCentroCusto.addRowsFromObject(obj);
      });

      // Adiciona a tabela tblCentroCusto à requisição
      rq.addTable(tblCentroCusto);

      if (this.requestSlice.length === 0) {
        const totalValueString = this.inputTotal
          .replace("R$ ", "")
          .replace(/\./g, "")
          .replace(",", "."); // Remove os caracteres não numéricos e substitui vírgula por ponto
        rq.setParam("total", totalValueString);
      }

      // Enviando a request para a API
      Api.request(rq)
        .then((rsp) => {
          if (rsp.ret_id > 0) {
            this.snackbarColor = "green darken-1";
            this.snackbarMsg = "Baixa realizada com sucesso!";
            this.$nextTick(() => {
              this.snackbar = true;
            });
            this.submitForm(); // Dispara a busca de informações novamente
          } else {
            this.ret_id = rsp.ret_id;
            this.ret_msg = rsp.ret_msg;
            this.snackbarMsg = "Erro ao processar a baixa.";
            this.snackbarColor = "red darken-1"; // Cor vermelha para erro

            this.$nextTick(() => {
              this.snackbar = true;
            });
          }
        })
        .catch((error) => {
          console.error("Erro ao baixar:", error);
          this.snackbarMsg = "Erro ao processar a baixa.";
          this.snackbarColor = "red darken-1"; // Cor vermelha para erro

          this.$nextTick(() => {
            this.snackbar = true;
          });
        });
    },
  },
});
